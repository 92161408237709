import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity } from '@shapeable/types';
import { Product } from '@shapeable/rhodian-types';
import { AspectRatio, breakpoints, theme } from '@shapeable/theme';
import { useEntity, ImageEntityBackground, ImageAsset } from '@shapeable/ui';
import { classNames } from '@shapeable/utils';
const cls = classNames('product-image');

// -------- Types -------->

export type ProductImageProps = Classable & HasChildren & { 
  entity?: Product;
  aspectRatio?: AspectRatio;  
};

export const ProductImageDefaultProps: Omit<ProductImageProps, 'entity'> = {
  aspectRatio: { base: 16 / 9 }
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const IconContainerStyles = breakpoints({
  base: css`
    position: absolute;
    left: 25px;
    top: 32%;
  `,
});



const IconStyles = breakpoints({
  base: css`
    text-align: center;
    width: 110px;
  `,
  tablet: css`
    width: 80px;
  `,
  desktop: css`
    width: 95px;
  `
});




// -------- Components -------->

const My = {
  Container: styled(ImageEntityBackground)<ContainerProps>`${ContainerStyles}`,
    IconContainer: styled.div`${IconContainerStyles}`,
      Icon: styled(ImageAsset)`${IconStyles}`,
      
};

export const ProductImage: Shapeable.FC<ProductImageProps> = (props) => {
  const { className, children, aspectRatio } = props;
  const entity = useEntity(props.entity);

  const{ banner, iconImage } = entity 

  const icon = !!iconImage;
  

  return (
   <My.Container aspectRatio={aspectRatio} entity={banner} className={className}>
    <My.IconContainer>
      {
        icon && <My.Icon entity={iconImage} />
      }
    </My.IconContainer>
   {children}
   </My.Container>
  )
};

ProductImage.defaultProps = ProductImageDefaultProps;
ProductImage.cls = cls;