import { 
  COLOR_WARM_GREY,
  createSliceLayout,
  SliceContentBlockLinks,
  SliceImageBlocks,
} from '@shapeable/ui';

import { ProductGrid } from '../entities/product-grid';
import { SliceProductBenefits } from '../entities/slice-product-benefits';
import { SliceProductIndustries } from '../entities/slice-product-industries';
import { SCOPE_ORANGE } from '../../theme';

export const SliceLayoutBenefits = createSliceLayout(
  SliceProductBenefits, {
    verticalPadding: 'large',
  }
);

export const SliceLayoutProductIndustries = createSliceLayout(
  SliceProductIndustries, {
    verticalPadding: 'small',
    boundary: 'content',
    defaultBackgroundColor: COLOR_WARM_GREY,
  }
);

export const SliceLayoutProductGrid = createSliceLayout(
  ProductGrid, {
    verticalPadding: 'small',
    boundary: 'content',
    props: {
      desktopAutoColumns: 2,
      tabletAutoColumns: 2,
    }
  }
);

export const SliceLayoutContentBlockLinks = createSliceLayout(
  SliceContentBlockLinks, {
    verticalPadding: 'medium',
    props: {
      hoverColor: SCOPE_ORANGE,
    }
  }
);
